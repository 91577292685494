//---------------------------------------------icons
.icon_w {
	position: relative;

	&.v1_mod {
		width: 3em;
		height: 2.2em;
		margin-right: .6em;
	}

	&.v2_mod {
		width: 1.8em;
		height: 2.6em;
		margin-right: .6em;
	}

	&.v3_mod {
		width: 4em;
		height: 2em;
		margin-right: .6em;
	}

	&.v4_mod {
		width: 2.6em;
		height: 2.2em;
		margin-right: .6em;
	}

	&.v5_mod {
		width: 2.6em;
		height: 2.8em;
		margin-right: .6em;
	}
}

.icon {
	&.info_mod {
		position: absolute;
		top: 0;
		left: 0;

		width: 100%;
		height: 100%;

		fill: #3E763D;
	}
}

.svg_icon {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}