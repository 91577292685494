.btn_base {
	@extend %transition_opacity;

	display: inline-flex;
	justify-content: center;
	align-items: center;
	align-content: center;

	min-width: 25.3rem;
	height: 6.9rem;
	padding: 0 2rem;

	color: $white;
	text-decoration: none;
	font-size: 1.8rem;
	font-weight: 400;
	letter-spacing: 0.056rem;

	background-image: url("../i/btn_bg.svg");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	background-size: cover;

	&:focus, &:hover {
		text-decoration: none;
		opacity: .8;
	}
}

.map_w {
	position: relative;

	width: 137.4em;
	height: 61.8em;
	margin: 0 auto;
	padding: 0 $gl_indent;

	&.v2_mod {
		width: 160em;
		height: 80em;
		margin-top: 10.5em;
	}
}

.map_bg_w {
	z-index: -1;

	position: absolute;
	top: 0;
	left: 6.4em;

	width: 100%;
	height: 100%;

	&.v2_mod {
		left: 3.6em;
	}

}

.map_bg {
	width: 100%;
	height: 100%;
}

.info_list_w {
	z-index: 1;

	position: absolute;
	left: 10.6em;
	bottom: 5.2em;

	&.v2_mod {
		@extend %global_flex_block_row_wrap_center_center;

		position: static;
		left: auto;
		bottom: auto;
	}
}

.info_list {
	margin: 0;
	padding: 0;
	list-style: none;

	.info_list_w.v2_mod & {
		@extend %global_flex_block_row_wrap_flex-start;
		margin: 0 -6em -2em 0;
	}
}

.info_item {
	$fz: 1.6;

	position: relative;

	margin-bottom: 1em;
	padding-left:(2.6em/$fz);

	color: $green;

	font-size: $fz + em;
	font-style: italic;

	.info_list_w.v2_mod & {
		$fz: 1.6;

		margin: 0;
		padding: 0 (6em/$fz) (2em/$fz) (5.4em/$fz);

		color: $green;
		font-size: $fz + em;
		font-style: normal;
		font-weight: 700;

		&::before {
			top: -4px;
			width: (3.2em/$fz);
			height: (3.2em/$fz);

			border-radius: 0;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}

	&::before {
		position: absolute;
		top: (.5em/$fz);
		left: 0;

		content: '';

		width: (1em/$fz);
		height: (1em/$fz);

		border-radius: 50%;

	}

	&.v1_mod::before {
		background-color: $green_3;
	}

	&.v2_mod::before {
		background-color: $blue;
	}

	&.v3_mod::before {
		background-color: $red;
	}

	&.v4_mod::before {
		border: 2px solid $green_4;
	}

	&.v5_mod::before {
		background-color: $blue;
	}

	&.v6_mod::before {
		background-color: $red;
	}

	&.v7_mod::before {
		border: 4px solid $green_4;
	}

	&.v8_mod::before {
		background-color: $green_3;
	}

}

.section_bottom {
	width: 100%;
	padding-top: 8.2em;
	text-align: center;
}

.popup_w {
	@extend %transition_opacity;

	z-index: 40;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	padding: 6em;

	background: rgba($black, .8);

	opacity: 0;
	pointer-events: none;

	&.active_popup {
		opacity: 1;
		pointer-events: auto;
	}
}

.popup_content {
	width: 100%;
	max-width: $page_width_2;
	height: 100%;
	max-height: 100%;
	margin: 0 auto;
	padding: (4em/1.6);

	font-size: 1.6em;

	overflow-x: hidden;
	overflow-y: auto;

	background: $white;
}

.close_btn {
	position: absolute;
	top: 3em;
	right: 3em;

	width: 5em;
	height: 5em;

	cursor: pointer;

	&::before,
	&::after {
		position: absolute;
		top: 2.4em;
		left: 0;

		content: '';

		width: 100%;
		height: 4px;
		background: #fff;
	}

	&::before {
		transform: rotate(-45deg);
	}

	&::after {
		transform: rotate(45deg);
	}

}