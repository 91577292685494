.map_balloons_w {
	z-index: 1;

	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
}

.map_balloon {
	@extend %global_flex_block_row_wrap_center_center;

	position: absolute;

	height: 3.4em;

	margin: auto;

	border-radius: 4px;
	background: $white;

	cursor: pointer;
}

.map_balloon_text {
	color: #3E763D;
	font-size: 1em;
	font-weight: 700;
}

.map_balloon_in {
	@extend %global_flex_block_row_wrap_flex-start_center;
}

.balloon_text {
	@extend %global_flex_block_row_wrap_flex-start_center;
}

.balloon_text_info {
	padding-left: .6em;
	text-transform: uppercase;
}

.balloon_pos_1 {
	left: -106.8em;
	top: 0;
	right: 0;
	bottom: 25.6em;

	width: 13.2em;
}

.balloon_pos_2 {
	left: -104.4em;
	top: 0;
	right: 0;
	bottom: 17.2em;

	width: 9.6em;
}

.balloon_pos_3 {
	left: -73.8em;
	top: 0;
	right: 0;
	bottom: 5.4em;

	width: 10.4em;
}

.balloon_pos_4 {
	left: -10em;
	top: 0;
	right: 0;
	bottom: 78.6em;

	width: 16em;
}

.balloon_pos_5 {
	left: 0;
	top: 0;
	right: 0;
	bottom: 70.4em;

	width: 20em;
}

.balloon_pos_6 {
	left: -3.6em;
	top: 0;
	right: 0;
	bottom: 63em;

	width: 12em;
}

.balloon_pos_7 {
	left: 0;
	top: 0;
	right: 0;
	bottom: 56em;

	width: 12em;
}

.balloon_pos_8 {
	left: -17.8em;
	top: 0;
	right: 0;
	bottom: 24em;

	width: 12em;
}

.balloon_pos_9 {
	left: 36.2em;
	top: 0;
	right: 0;
	bottom: 82em;

	width: 12em;
}

.balloon_pos_10 {
	 left: 52.2em;
	 top: 0;
	 right: 0;
	 bottom: 74em;

	 width: 14em;
 }

.balloon_pos_11 {
	left: 54.2em;
	top: 0;
	right: 0;
	bottom: 68em;

	width: 14em;
}

.balloon_pos_12 {
	left: 55.2em;
	top: 0;
	right: 0;
	bottom: 58em;

	width: 12em;
}

.balloon_pos_13 {
	left: 64.2em;
	top: 0;
	right: 0;
	bottom: 50em;

	width: 16em;
}

.balloon_pos_14 {
	left: 46em;
	top: 0;
	right: 0;
	bottom: 22em;

	width: 18em;
}

.balloon_pos_15 {
	left: 2em;
	top: 0;
	right: 0;
	bottom: 4em;

	width: 14em;
}

.balloon_pos_16 {
	left: 28em;
	top: 0;
	right: 0;
	bottom: -8em;

	width: 12em;
}

.balloon_pos_17 {
	left: 10em;
	top: 0;
	right: 0;
	bottom: -36.8em;

	width: 9em;
}

.balloon_pos_18 {
	left: 52em;
	top: 0;
	right: 0;
	bottom: -19.8em;

	width: 9em;
}

.balloon_pos_19 {
	left: 37em;
	top: 0;
	right: 0;
	bottom: -60.8em;

	width: 9em;
}

.balloon_pos_20 {
	 left: 95em;
	 top: 0;
	 right: 0;
	 bottom: -2.8em;

	 width: 9em;
 }

.balloon_pos_21 {
	left: 83em;
	top: 0;
	right: 0;
	bottom: -31.8em;

	width: 16em;
}