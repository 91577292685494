//------------------------------------------------------------base styles
html {
	height: 100%;
	font: 400 calc(100vw / 1920 * 10)/1.33 $aller;
}

body {
	position: relative;
	height: 100%;
	font-size: $font_size_base;
	color: $color_text_base;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: $body_bg;
}

.wrapper {
	position: relative;
	min-height: 100%;
	height: auto !important;
	overflow: hidden;
}

.base {
	position: relative;
	padding: 0;
}

.container {
	max-width: $page_width_2;
	width: 100%;
	margin: 0 auto;
	padding: 0 $gl_indent;

	&.sm_mod {
		max-width: $page_width;
	}

}

a {
	text-decoration: none;
	color: $color_link_base;
	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

.elements_list {
	padding: 3rem;
}

//------------------------------------------------------------base styles###