//------------------------------------------------------------layout
.header {
	position: fixed;
	height: $height_header;
	width: 100%;
	top: 0;
	left: 0;
	will-change: transform;

	@include z-index(header);
}

//------------------------------------------------------------layout###