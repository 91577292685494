//---------------------------------------------sections
.section {
	position: relative;

	width: 100%;
	padding: 8em 0 9em;

	font-size: 1rem;
}


.section_title {
	color: $green;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
	&.v1_mod {
		$fz: 6;

		margin-bottom: (3.8em/$fz);
		font-size: $fz + em;
	}
	&.v2_mod {
		$fz: 7;

		position: relative;

		margin-bottom: (8em/$fz);
		padding-bottom: (6em/$fz);

		text-transform: none;
		font-size: $fz + em;

		&::before {
			position: absolute;

			left: 0;
			bottom: 0;
			right: 0;

			content: '';

			width: (17.8em/$fz);
			height: 2px;
			margin: auto;

			background: $green_2;
		}

	}

	&.values_mod {
		position: relative;
		margin-bottom: (9.4em/6);
		padding-bottom: (6em/6);

		text-transform: none;
		letter-spacing: -.08rem;
	}



}

.values_title_line {
	position: absolute;
	bottom: -4px;
	left: 50%;

	width: (18em/6);
	height: .3rem;
	margin-left: -(9em/6);

	background-color: $green_2;
}

.section_subtitle {
	&.v1_mod {
		margin-bottom: (4.6em/1.8);

		color: $green;
		font-size: 1.8em;
		font-style: italic;
		text-align: center;
	}

	&.v2_mod {
		margin-bottom: (8.8em/1.8);

		color: $green;
		font-size: 1.8em;
		font-style: italic;
		text-align: center;
	}
}