.icon {
	display: inline-block;
	width: 1em;
	height: 1em;
	fill: currentColor;
}

.icon-cheese {
	font-size:(14.2/10)*1rem;
	width:(19.1/14.2)*1em;
}
.icon-milk {
	font-size:(15.8/10)*1rem;
	width:(11.6/15.8)*1em;
}
