//---------------------------------------------fonts
$font_path: "../fonts/";
$aller: 'Aller', sans-serif;
$quicksand_bold: 'QuicksandBold', sans-serif;
$font_size_base: 1.6rem;
//---------------------------------------------fonts###

//---------------------------------------------colors
$white: #fff;
$black: #000;

$gray: #666;

$green: #44695b;
$green_2: #43b648;
$green_3: #9ad19e;
$green_4: #40675b;
$green_5: #8BAD8B;
$green_6: #45ac34;
$red: #e23246;
$red_2: #eb485a;
$blue: #417389;
$blue_2: #417389;

$color_text_base: $black;
$color_link_base:#4aa3df;
$body_bg: $white;
//---------------------------------------------colors###

//---------------------------------------------z-index
$z-index: (
	popap : 9999,
	header : 100,
	footer : 100
);
//---------------------------------------------z-index###

//---------------------------------------------layout
$height_footer: 10rem;
$height_header: 10rem;
$page_width: 122rem;
$page_width_2: 141.4rem;

//---------------------------------------------layout###

//---------------------------------------------global values
$gl_indent: 2rem;
$gl_radius:.5rem;
//---------------------------------------------global values###

//---------------------------------------------media points
$point_1: 1200px;
$point_2: 1024px;
$point_3: 1023px;
$point_4: 768px;
$point_5: 640px;
$point_6: 480px;
$point_7: 375px;
$point_8: 320px;
//---------------------------------------------media points

//---------------------------------------------forms
$input_height:4rem;
$input_offset:0 2rem;
$input_font_size:$font_size_base;
$input_border_color:#ccc;
$input_border_color_active:$black;
$input_placeholder_color: $black;
$input_text_color:$black;
$input_radius:$gl_radius;
//---------------------------------------------forms###