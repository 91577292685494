//---------------------------------------------value_list
.values_list {
	@extend %global_flex_block_row_wrap_flex-start;

	margin: 0 -2em;
}

.values_list_item {
	width: 25%;
	padding: 0 2em;
}

.value_block {
	text-align: center;
}

.value_block_title {
	position: relative;

	margin-bottom: (3.6em/2.4);

	color: transparent;
	font-size: 2.4em;
	font-weight: 700;
	letter-spacing: 0;
}

.value_block_title_in {
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	text-align: center;

	color: $gray;
}

.value_block_w_img {
	@extend %global_flex_block_row_wrap_flex-start_center;

	min-height: 10.8em;
	margin-bottom: 4.8em;
}

.value_block_w_in {
	margin: 0 auto;

	&.v1_mod {
		width: 10.4em;
		height: 9em;
	}
	&.v2_mod {
		width: 8em;
		height: 10.8em;
	}
	&.v3_mod {
		width: 13.6em;
		height: 7.4em;
	}
	&.v4_mod {
		width: 11.6em;
		height: 7em;
	}
}

.value_block_img {
	display: block;
	height: 100%;
	width: 100%;
	object-fit: cover;


}

.value_block_w_subtitle {
	position: relative;

	padding-bottom: 2.4em;
	margin-bottom: 2em;

	&:before {
		position: absolute;
		bottom: 0;
		left: 0;

		width: 100%;
		height: 2px;

		background: url('../i/border.svg') repeat-x;

		content: "";
	}

}

.value_block_subtitle {
	position: relative;
	display: block;

	color: transparent;
	font-size: 1.8em;
	font-weight: 700;
	line-height: 1.6;
}

.value_block_subtitle_in {
	position: absolute;
	top: 0;
	left: 0;

	height: 100%;
	width: 100%;

	color: $gray;
	text-align: center;
}

.value_block_descr {
	color: $gray;
	font-size: 1.8em;
	font-weight: 400;
	letter-spacing: -.02rem;
	line-height: 1.6;
}