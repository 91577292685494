.marker_w {
	position: absolute;
	top: 0;
	left: 6.4em;

	width: 100%;
	height: 100%;

}

.marker_item {
	z-index: 1;

	position: absolute;

	display: block;
	margin: auto;

	border-radius: 50%;

	text-decoration: none;

	&.sales_mod {
		width: .7em;
		height: .7em;
		background: $blue;
	}

	&.units_mod {
		width: 1.2em;
		height: 1.2em;

		border-radius: 50%;
		border: 2px solid $green_5;
		background: $white;
	}

	&.centers_mod {
		width: .8em;
		height: .8em;
		background: $red;
	}
}

.splash_el {
	z-index: -1;

	position: absolute;
	top: -.8em;
	left: -.8em;
	right: -.8em;
	bottom: -.8em;

	opacity: 1;
	transform: scale(0);

	border-radius: 50%;

	.sales_mod & {
		border: 4px solid $blue;
	}

	.units_mod & {
		border: 4px solid $green_5;
	}

	.centers_mod & {
		border: 4px solid $red;
	}
}

//sales markers
.pos_1 {
	 left: -116.8em;
	 top: 0;
	 right: 0;
	 bottom: 22em;
 }

.pos_2 {
	left: -101.8em;
	top: 0;
	right: 0;
	bottom: 19em;
}

.pos_3 {
	left: -90em;
	top: 0;
	right: 0;
	bottom: 16em;
}

.pos_4 {
	left: -90em;
	top: 0;
	right: 0;
	bottom: 16em;
}

.pos_5 {
	left: -84.6em;
	top: 0;
	right: 0;
	bottom: 26em;
}

.pos_6 {
	left: -83em;
	top: 0;
	right: 0;
	bottom: 25em;
}

.pos_7 {
	left: -81.6em;
	top: 0;
	right: 0;
	bottom: 27em;
}

.pos_8 {
	left: -78.8em;
	top: 0;
	right: 0;
	bottom: 28.6em;
}

.pos_9 {
	left: -98.8em;
	top: 0;
	right: 0;
	bottom: 40.8em;
}

.pos_10 {
	left: -114.2em;
	top: 0;
	right: 0;
	bottom: 32em;
}

.pos_11 {
	left: 10.2em;
	top: 0;
	right: 0;
	bottom: -45em;
}

.pos_12 {
	left: 12.2em;
	top: 0;
	right: 0;
	bottom: -30.2em;
}

.pos_13 {
	left: -2.2em;
	top: 0;
	right: 0;
	bottom: -6.8em;
}

.pos_14 {
	left: -5.8em;
	top: 0;
	right: 0;
	bottom: 8.8em;
}

.pos_15 {
	left: 14.4em;
	top: 0;
	right: 0;
	bottom: 9em;
}

.pos_16 {
	left: 35.8em;
	top: 0;
	right: 0;
	bottom: 7.2em;
}

.pos_17 {
	left: 69.8em;
	top: 0;
	right: 0;
	bottom: 15.2em;
}

.pos_18 {
	left: -10.2em;
	top: 0;
	right: 0;
	bottom: 22.4em;
}

.pos_19 {
	left: -4.2em;
	top: 0;
	right: 0;
	bottom: 30.2em;
}

.pos_20 {
	left: 5.4em;
	top: 0;
	right: 0;
	bottom: 32.3em;
}

.pos_21 {
	left: -26.4em;
	top: 0;
	right: 0;
	bottom: 41.6em;
}

.pos_22 {
	z-index: 4;

	left: -23em;
	top: 0;
	right: 0;
	bottom: 42em;
}

//centers markers
.pos_23 {
	z-index: 5;

	left: -88.8em;
	top: 0;
	right: 0;
	bottom: 25.8em;
}

.pos_24 {
	left: -88.2em;
	top: 0;
	right: 0;
	bottom: 28.6em;
}

.pos_25 {
	z-index: 2;

	left: -87em;
	top: 0;
	right: 0;
	bottom: 30.8em;
}

.pos_26 {
	left: -88.2em;
	top: 0;
	right: 0;
	bottom: 32em;
}

.pos_27 {
	left: -2.8em;
	top: 0;
	right: 0;
	bottom: -9.6em;
}

.pos_28 {
	left: 11.8em;
	top: 0;
	right: 0;
	bottom: -42.8em;
}

.pos_29 {
	left: 29.4em;
	top: 0;
	right: 0;
	bottom: 3.6em;
}

.pos_30 {
	left: 35em;
	top: 0;
	right: 0;
	bottom: 5.2em;
}

.pos_31 {
	left: 85.2em;
	top: 0;
	right: 0;
	bottom: 7em;
}

.pos_32 {
	z-index: 2;

	left: 85em;
	top: 0;
	right: 0;
	bottom: 8em;
}

.pos_33 {
	left: -10em;
	top: 0;
	right: 0;
	bottom: 20.4em;
}

.pos_34 {
	left: -.4em;
	top: 0;
	right: 0;
	bottom: 30.6em;
}

.pos_35 {
	left: -28em;
	top: 0;
	right: 0;
	bottom: 40em;
}

.pos_36 {
	z-index: 3;

	left: -24.4em;
	top: 0;
	right: 0;
	bottom: 42.4em;
}

.pos_37 {
	z-index: 2;

	left: -23.8em;
	top: 0;
	right: 0;
	bottom: 43em;
}

.pos_38 {
	left: -24.6em;
	top: 0;
	right: 0;
	bottom: 43.5em;
}

.pos_39 {
	left: -16.6em;
	top: 0;
	right: 0;
	bottom: 44.4em;
}

.pos_40 {
	z-index: 2;

	left: -17.2em;
	top: 0;
	right: 0;
	bottom: 43.4em;
}

.pos_41 {
	z-index: 3;

	left: -16.2em;
	top: 0;
	right: 0;
	bottom: 43.4em;
}

.pos_42 {
	left: -18.2em;
	top: 0;
	right: 0;
	bottom: 43.4em;
}

.pos_43 {
	left: -18.2em;
	top: 0;
	right: 0;
	bottom: 41.2em;
}

.pos_44 {
	left: -16.8em;
	top: 0;
	right: 0;
	bottom: 40.2em;
}

.pos_45 {
	left: -86.6em;
	top: 0;
	right: 0;
	bottom: 25.2em;
}

.pos_46 {
	left: -86em;
	top: 0;
	right: 0;
	bottom: 27.2em;
}

.pos_47 {
	left: 30em;
	top: 0;
	right: 0;
	bottom: 5.8em;
}

.pos_48 {
	z-index: 2;

	left: -8.6em;
	top: 0;
	right: 0;
	bottom: 21.8em;
}

.pos_49 {
	left: 1em;
	top: 0;
	right: 0;
	bottom: 32em;
}

.pos_50 {
	left: -18.3em;
	top: 0;
	right: 0;
	bottom: 38.4em;
}

.pos_51 {
	left: -17.8em;
	top: 0;
	right: 0;
	bottom: 46em;
}

.pos_52 {
	left: -24.5em;
	top: 0;
	right: 0;
	bottom: 40.4em;
}

.pos_53 {
	left: -26em;
	top: 0;
	right: 0;
	bottom: 39.6em;
}